@media (max-width: 768px) {
  .beach {
    left: -21rem;
    position: fixed;
    height: 100%;
    width: 80rem;
    filter: blur(3.8px);
    overflow: auto;
  }
  .links {
    margin-top: 7.5rem;
    overflow: auto;
    position: absolute;
    width: fit-content;
    top: 20%;
    left: 50%;
    padding-bottom: 8rem;

    transform: translate(-50%, -20%);
    text-align: center;
    margin-bottom: 13rem;
    height: fit-content;
  }
  .links-title {
    color: white;
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
  .links-photo {
    width: 12rem;
    margin-bottom: 1.9rem;
    border-radius: 100%;
    margin-top: 8rem;
  }
  .links-button {
    width: fit-content;
    border: white 0.1rem solid;
    border-radius: 1.4rem;
    color: white;
    padding-top: 0.93rem;
    padding: 1rem;
    font-size: 1.6rem;
    letter-spacing: 0.2rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 1.6rem;
    text-decoration: none;
  }
}

@media (min-width: 769px) {
  .beach {
    position: fixed;
    height: 100%;
    width: 100%;
    filter: blur(3.8px);
    overflow: auto;
  }
  .links {
    margin-top: 7.5rem;
    overflow: auto;
    position: absolute;
    width: fit-content;
    top: 20%;
    padding-bottom: 8rem;
    left: 50%;
    transform: translate(-50%, -20%);
    text-align: center;
    margin-bottom: 13rem;
    height: fit-content;
  }
  .links-title {
    color: white;
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
  .links-photo {
    width: 12rem;
    margin-bottom: 1.9rem;
    border-radius: 100%;
    margin-top: 8rem;
  }
  .links-button {
    max-width: fit-content;
    min-width: 3rem;
    border: white 0.1rem solid;
    border-radius: 1.4rem;
    color: white;
    padding: 1rem;
    padding-top: 0.93rem;
    font-size: 1.6rem;
    letter-spacing: 0.2rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 1.6rem;
    text-decoration: none;
  }
}

.helper {
  height: 100%;
  position: relative;
}
